import Loader from "components/Loader/Loader";
import "./loader_sreen.scss";
const LoaderSreen: React.FC = () => {
  return (
    <div className="loader-screen">
      <Loader />
    </div>
  );
};

export default LoaderSreen;
