import React, { useEffect, useRef, useState } from "react";
import "./dropdown.css";
import Arrow from "./Icons/Arrow/Arrow";
import X from "./Icons/X/X";

interface Option {
  label: string;
  value: any;
}

interface DropdownProps {
  placeHolder: string;
  options: Option[];
  isMulti: boolean;
  isSearchable: boolean;
  onChange: (value: any) => void;
  value: Option[] | null;
  size: string | null;
  fullWidth: boolean | null;
  setIsOpen: boolean | null;
}

function Dropdown({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  value,
  size,
  fullWidth,
  setIsOpen,
}: any) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(
    value ? value : isMulti ? [] : null
  );
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
      searchWrapperRef?.current?.scrollIntoView();
    }
  }, [showMenu]);
  // useEffect(() => {
  //   const handler = (e: MouseEvent) => {
  //     if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
  //       // setShowMenu(false);
  //     }
  //   };

  //   window.addEventListener("click", handler);
  //   return () => {
  //     window.removeEventListener("click", handler);
  //   };
  // }, []);

  const handleInputClick = () => {
    setShowMenu(!showMenu);
  };

  // useEffect(() => {
  //   setShowMenu(setIsOpen);
  // }, [setIsOpen]);

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue?.map((option: Option, index: number) => (
            <div key={`${option.value}-${index}`} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <X className="dropdown-tag-close-icon-xnodui" />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue?.value;
  };

  const removeOption = (option: Option) => {
    return selectedValue?.filter((o: any) => o.value !== option.value);
  };

  const onTagRemove = (
    e: React.MouseEvent<HTMLSpanElement>,
    option: Option
  ) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: Option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o: any) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };

  const isSelected = (option: Option) => {
    if (isMulti) {
      return (
        selectedValue.filter((o: any) => o.value === option.value).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option: any) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div
      className={`dropdown-container-xnodui dropdown-container-xnodui--${size} ${
        isMulti
          ? selectedValue.length
            ? "dropdown-container-xnodui--selected"
            : ""
          : selectedValue
          ? "dropdown-container-xnodui--selected"
          : ""
      } ${fullWidth ? "dropdown-container-xnodui--full-width" : ""}`}
    >
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className="dropdown-input-xnodui"
      >
        <div
          className={`dropdown-selected-value-xnodui ${
            !selectedValue || selectedValue.length === 0 ? "placeholder" : ""
          }`}
        >
          {getDisplay()}
        </div>
        <Arrow
          className={`dropdown-tool-icon-xnodui ${
            showMenu ? "dropdown-tool-icon-xnodui--tanslate" : ""
          }`}
        />
      </div>
      <div
        className={`dropdown-menu-xnodui dropdown-menu-xnodui--${
          showMenu ? "show" : "hide"
        }`}
      >
        {isSearchable && (
          <div ref={searchWrapperRef} className="search-box">
            <input
              className="form-control"
              onChange={onSearch}
              value={searchValue}
              ref={searchRef}
            />
          </div>
        )}
        {getOptions().map((option: any) => (
          <div
            onClick={() => onItemClick(option)}
            key={option.value}
            className={`dropdown-item-xnodui ${
              isSelected(option) && "selected"
            }`}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
