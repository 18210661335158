interface RoutesInteface {
  HOME: string;
  BZRP_TOUR: string;
  RECAP: string;
}
const ROUTES: RoutesInteface = {
  HOME: "/",
  BZRP_TOUR: "/bzrp-tour",
  RECAP: "/material-exclusivo",
};
export default ROUTES;
