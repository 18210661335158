import { Link } from "react-router-dom";
import "./bzrp-tour-btn.scss";
import { ROUTES } from "models";

const BzrpTourBtn: React.FC = () => {
  return (
    <div className="bzrp-tour-btn bzrp-tour-btn">
      <Link
        className="bzrp-tour-btn__link"
        to={ROUTES.BZRP_TOUR}
        title="Próximas fechas Bizarrap"
      >
        <img
          className="bzrp-tour-btn__image"
          src="https://bzrpbucket.s3.us-east-2.amazonaws.com/flyer/flyer.webp"
          title="Próximas fechas Bizarrap"
          alt="Próximas fechas Bizarrap"
        />
        <span className="bzrp-tour-btn__text" title="Bzrp Tour">
          BZRP TOUR!{" "}
        </span>
        <span
          className="bzrp-tour-btn__text-sub"
          title="Próximas fechas Bizarrap"
        >
          PRÓXIMAS FECHAS
        </span>
      </Link>
    </div>
  );
};

export default BzrpTourBtn;
